<template>
  <div class="tableBox">
    <el-table
      ref="table"
      :stripe="finalConfig.stripe"
      :border="finalConfig.border"
      :size="finalConfig.size"
      :header-cell-style="finalConfig.headerCellStyle"
      :data="tableData"
      v-loading="loading"
      @selection-change="selections => $emit('update:selections', selections)"
      :highlight-current-row="finalConfig.highlightCurrentRow"
      @current-change="handleCurrentChange"
      height="100%"
    >
      <template v-for="(item, index) in columns">
        <el-table-column
          :key="`columns-${index}`"
          v-if="item.prop === '_action'"
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :width="item.width"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            <template v-for="(action, actionIndex) in item.actions">
              <el-button
                v-if="executeCondition(action.condition, scope)"
                :key="`columns-${index}-_action-${actionIndex}`"
                :type="action.type"
                :icon="action.icon"
                @click="
                  action.event ? action.event(scope.row) : null;
                  cellMountAfter(action, scope);
                "
                size="mini"
              >
                {{ action.title }}
              </el-button>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          :key="`columns-${index}`"
          v-else-if="item.dictTranslate"
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :width="item.width"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            {{
              valueToName(filterDictionary(item.dictCode), scope.row[item.prop])
            }}
          </template>
        </el-table-column>
        <el-table-column
          :key="`columns-${index}`"
          v-else-if="item.valueSuffix"
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :width="item.width"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            {{ scope.row[item.prop] }} {{ item.valueSuffix }}
          </template>
        </el-table-column>

        <el-table-column
          :key="`columns-${index}`"
          v-else-if="item.mask"
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :width="item.width"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            {{ scope.row[item.prop].replace(/(\d{3})(\d{4})(\d{4})/,"$1****$3") }}
          </template>
        </el-table-column>

        <el-table-column
          :key="`columns-${index}`"
          v-else-if="item.ossUrl"
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :width="item.width"
          :min-width="item.minWidth"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="generateValidUrl(scope.row[item.prop])"
              :preview-src-list="[generateValidUrl(scope.row[item.prop])]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column
          :key="`columns-${index}`"
          v-else
          :header-align="item.headerAlign ? item.headerAlign : 'center'"
          :align="item.align ? item.align : 'center'"
          :type="item.type"
          :label="item.label"
          :prop="item.prop"
          :fixed="item.fixed"
          :formatter="item.formatter"
          :width="item.width"
          :min-width="item.minWidth"
        >
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      v-if="finalConfig.pagination"
      style="margin-top: 10px"
      :total="pagination.total"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[30, 50, 100]"
      background
      :disabled="loading"
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>
    <div class="tableFootBtn">
      <el-button
        v-for="(item, index) in tools"
        :key="`tools-${index}`"
        :type="item.type"
        size="mini"
        :icon="item.icon"
        @click="item.event"
      >
        {{ item.title }}
      </el-button>
    </div>
    <order-info ref="order-info"></order-info>
  </div>
</template>

<script>
import oss from '../utils/oss';

const client = oss.client();

export default {
  name: 'DynamicTable',
  components: {
    // 异步引用，解决组件之间的循环引用导致的问题
    OrderInfo: () => import('../views/OrderManagement/OrderInfo/Index.vue'),
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    tableData: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({
        total: 0,
        pageSize: 30,
        currentPage: 1,
      }),
    },
    selections: {
      type: Array,
      default: () => [],
    },
    tools: {
      type: Array,
      default: () => [],
    },
    selectRow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      initConfig: {
        stripe: true,
        border: true,
        size: 'mini',
        headerCellStyle: {
          background: '#f2f2f2',
        },
        pagination: true,
        highlightCurrentRow: false,
      },
    };
  },
  computed: {
    finalConfig() {
      const config = {
        ...this.initConfig,
        ...this.config,
      };
      return config;
    },
  },
  created() {
    // console.log(this);
  },
  activated() {
    this.$nextTick(() => {
      this.doLayout();
    });
  },
  methods: {
    updatePagination(info) {
      this.$emit('update:pagination', info);
      this.$emit('update');
    },
    sizeChange(pageSize) {
      this.updatePagination({
        ...this.pagination,
        pageSize,
      });
    },
    currentChange(currentPage) {
      this.updatePagination({
        ...this.pagination,
        currentPage,
      });
    },
    doLayout() {
      this.$refs.table.doLayout();
    },
    cellMountAfter(action, scope) {
      const { id } = action;
      switch (id) {
        case 'see':
          this.openInfoPage(scope);
          break;
        default:
          break;
      }
    },
    openInfoPage({ row }) {
      const { id, orderId } = row;
      if (id || orderId) {
        const targetComponent = this.$refs['order-info'];
        targetComponent.init(orderId || id);
        targetComponent.open();
      }
    },
    setCurrent(row) {
      this.$refs.table.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.$emit('update:selectRow', val);
    },
    executeCondition(condition, { row }) {
      if (
        condition === undefined ||
        condition === true ||
        condition === 'true'
      ) {
        return true;
      }

      const paramsMap = {
        row,
      };
      const params = Object.values(paramsMap);
      const props = Object.keys(paramsMap);

      const expression = `
              'use strict'
              let window = undefined;
              let document = undefined;
              let alert = undefined;
              let a = undefined;
              return ${condition};
          `;
      props.push(expression);
      try {
        const evalFunc = new Function(...props);
        const result = evalFunc(...params);
        return !!result;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    generateValidUrl(url) {
      return url ? client.signatureUrl(url) : '';
    },
  },
};
</script>

<style lang="scss">
</style>
